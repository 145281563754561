<div>
  <div class="px-10  min-w-[776px] flex justify-between">
    <div>
      <div class="w-full -mt-10">
        <!-- <header class="flex gap-6">
          <button class="w-24 h-10 rounded-md bg-[#E8F0FB] text-[#102866]">Search</button>
        </header> -->
      </div>
    </div>
    <div class="-mt-10">
      <header class="flex justify-end items-center  px-5 bg-white">
        <div>
          <img src="../../../../assets/icons/all.svg" alt="Profile Picture" class="w-8 h-8 rounded-full mr-2">
        </div>
        <div>
          <span class="text-sm font-medium text-gray-800">Tharuka Ravisara</span>
        </div>
        <div>
<!--          <img src="../../../../assets/icons/pending.svg" alt="dropdown arrow" class="ml-3">-->
        </div>
      </header>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {Subscription} from "rxjs";
import {AuthService} from "../../../../core";


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  private querySub: Subscription | undefined;

  ngOnInit() {
    this.querySub = this.activatedRoute.queryParams.subscribe(params => {
      const keyValue = params['key'];
      console.log(keyValue);

      this.authService.signIn(keyValue).subscribe({
        next: (res: any) => {
          console.log(res)
        },
        error: (err: any) => {
          console.log(err)
        }
      })

      // this.authService.signIn(keyValue);// Use the extracted key as needed
    });
  }

  ngOnDestroy() {
    if (this.querySub)
      this.querySub.unsubscribe(); // Don't forget to unsubscribe to prevent memory leaks
  }

}

<div>
  <div class="flex flex-row gap-6 justify-between justify-items-center mx-8 my-4">
    <div class="bg-[#FDEBF9] text-left py-6 px-8 rounded-lg w-full hover:bg-[#fadbf3]" (click)="selectCard('all')"
    [ngClass]="{ 'border-2 border-[#dea3d2]': selectedCard === 'all' }">
      <h2 class="text-xl font-light text-gray-800">All</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{totalCount}}</p>
    </div>
    <div class="bg-[#ecc7b5] text-left py-6 px-8 rounded-lg w-full hover:bg-[#f7ded2]" (click)="selectCard('pending')"
    [ngClass]="{ 'border-2 border-[#d0a996]': selectedCard === 'pending' }">
      <h2 class="text-xl font-light text-gray-800">Pending</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{pendingCount}}</p>
    </div>
    <div class="bg-[#E8F0FB] text-left py-6 px-8 rounded-lg w-full hover:bg-[#dce8f8]" (click)="selectCard('active')"
    [ngClass]="{ 'border-2 border-[#7d96b9]': selectedCard === 'active' }">
      <h2 class="text-xl font-light text-gray-800">Active</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{checkedInCount}}</p>
    </div>
    <div class="bg-[#fdf3ce] text-left py-6 px-8 rounded-lg w-full hover:bg-[#efe3ba]" (click)="selectCard('approved')"
    [ngClass]="{ 'border-2 border-[#c2b588]': selectedCard === 'approved' }">
      <h2 class="text-xl font-light text-gray-800">Approved</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{approvedCount}}</p>
    </div>
  </div>
</div>

@if (selectedCard === 'pending') {
<app-pending-visitor-table></app-pending-visitor-table>
} @else if (selectedCard === 'active') {
<app-active-visitor-table></app-active-visitor-table>
} @else if (selectedCard === 'approved') {
<app-approved-visitor-table></app-approved-visitor-table>
} @else if (selectedCard === 'all') {
<app-visitor-table></app-visitor-table>
}

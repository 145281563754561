<div>
  <div class="flex flex-row gap-6 justify-between justify-items-center mx-8 my-4">
    <div class="bg-[#FFEFE7] text-left py-6 px-8 rounded-lg w-full hover:bg-[#f7ded2]" >
      <h2 class="text-xl font-light text-gray-800">Pending</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{pendingCount}}</p>
    </div>
    <div class="bg-[#E8F0FB] text-left py-6 px-8 rounded-lg w-full hover:bg-[#dce8f8]" >
      <h2 class="text-xl font-light text-gray-800">Active</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{checkedInCount}}</p>
    </div>
    <div class="bg-[#fdf3ce] text-left py-6 px-8 rounded-lg w-full hover:bg-[#efe3ba]" >
      <h2 class="text-xl font-light text-gray-800">Approved</h2>
      <p class="mt-2 text-3xl font-medium text-gray-800">{{approvedCount}}</p>
    </div>
  </div>
</div>


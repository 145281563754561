export enum APIRequestResources {
    IdentityService = 'identity',
    UsersService = 'identity/users',
    RolesService = 'identity/roles',
    GroupsService = 'identity/groups',
    ResourcesService = 'identity/resources',
    ApplicationService = 'application',
    VisitorService = 'visitor',
    EmployeeService = 'employee',
  }

<app-header></app-header>
<div class="main-container">
  <div class="heading">
    <h1 class="text-lg font-medium ">All Visitors</h1>
    <select  [(ngModel)]="filterStatus" (ngModelChange)="onFilterStatusChange()" class="w-fit h-fit absolute align-left rounded-md p-2 border ">
      <option value="">All Visitors</option>
      <option value="Approved">Approved Visitors</option>
      <option value="CheckedIn">Active Visitors</option>
      <option value="CheckedOut">CheckedOut Visitors</option>
      <option value="Pending">Pending Visitors</option>
      <option value="Rejected">Rejected Visitors</option>
    </select>
    <div>
      <input
        type="text"
        class="w-64 font-light h-10 rounded-md p-2 border "
        placeholder="Search Name or NIC"
        [(ngModel)]="searchTerm"
        (ngModelChange)="getAllVisitors()"
      />
    </div>
  </div>

  <div>
    @if (visitorResponseDTOS.length > 0) {
      @for (visitors of visitorResponseDTOS; track visitors) {
        <div class="bg-[#FAFAFA] flex flex-row gap-6 px-10 justify-between p-3 rounded-md items-center min-w-[1171px]">
          <div class="w-1/3 py-4">
            <div class="flex  gap-4 justify-between">
              <div>
                <h6>{{ visitors.title }}.{{ visitors.fullName }}</h6>
              </div>
              <div class="bg-[#E8F0FB] text-[#102866] p-1 rounded-md -mt-3 max-w-9 max-h-7 px-3">
                <h6>{{ visitors.visitorType }}</h6>
              </div>
            </div>
            <p class="text-sm -mt-1 opacity-80">{{ visitors.company }}</p>
            <p class="text-sm mt-2">{{ visitors.nicNo }} - {{ visitors.contactNo }}</p>
            <p class="text-sm">{{ visitors.email }}</p>
          </div>
          <div>
            <svg width="1" height="88" viewBox="0 0 1 88" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.25" x2="0.25" y2="88" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
            </svg>
          </div>
          <div class="w-1/3 py-4">
            <div class="flex  gap-4">
              <div>
                <h6>{{ visitors.requestDate }}</h6>
              </div>
              <div class="bg-[#E8F0FB] text-[#102866] px-1 rounded-md">
                <h6>{{ visitors.requestTime }}</h6>
              </div>
            </div>
            <p class="mt-4">{{ visitors.purpose }}</p>
          </div>
          <div>
            <svg width="1" height="88" viewBox="0 0 1 88" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.25" x2="0.25" y2="88" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
            </svg>
          </div>
          <div class="w-1/3 py-4">
            <p>Request by - {{ visitors.requestBy }}</p>
            <p>To Contact - {{ visitors.contactPerson }}</p>
            <div class="flex gap-10 mt-4">
              @if (visitors.status === 'Approved') {
                <div
                  class="font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center bg-[#BDF4CD] text-[#10661E] rounded-md">
                  Approved
                </div>
              } @else if (visitors.status === 'Pending') {
                <div
                  class="font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center bg-[#D5F2FF] text-[#102D66] rounded-md">
                  Pending
                </div>
              } @else if (visitors.status === 'CheckedOut') {
                <div
                  class="font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center bg-[#f9d5ff] text-[#651066] rounded-md">
                  CheckedOut
                </div>
              } @else if (visitors.status === 'CheckedIn') {
                <div
                  class="font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center bg-[#F4EBBD] text-[#665810] rounded-md">
                  Active
                </div>
              } @else if (visitors.status === 'Rejected') {
                <div
                  class="font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center bg-[#FF9F9F] text-[#5F0404] rounded-md">
                  Rejected
                </div>
              }
            </div>
          </div>
        </div>
        <br>
      }
    } @else {
      @if (isLoading) {
        <app-loading></app-loading>
      } @else {
        <div class="text-center text-l pt-3 mt-4">
          There are no visitor any records to show.
        </div>
      }
    }
  </div>
  <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" (pageChanged)="onPageChanged($event)"></app-pagination></div>

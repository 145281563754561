<div class="main-container">
  <div class="flex justify-between">
    <div class="heading">
      <h1 class="text-lg font-medium ">All Visitors</h1>
    </div>
    <div>
      <select name="1" id="" class="form-input" (click)="applyFilter()" [(ngModel)]="selectedStatusFilter">
        <option value="">All</option>
        <option value="Approved">Approved</option>
        <option value="Pending">Pending</option>
        <option value="Rejected">Rejected</option>
        <option value="CheckedIn">CheckedIn</option>
        <option value="CheckedOut">CheckedOut</option>
      </select>
    </div>
  </div>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Company</th>
          <th>Contact Person</th>
          <th>Request Time</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        @for (visitors of filterVisitorsDTO; track visitors) {
        <tr>
          <td>{{visitors.title}}. {{visitors.fullName}}<h6 class="text-[#686868] text-xs">{{visitors.nicNo}}</h6>
          </td>
          <td>{{visitors.company}}</td>
          <td>{{visitors.contactPerson}}</td>
          <td>{{visitors.requestDate}}<h6 class="text-[#686868] text-xs">{{visitors.requestTime}}</h6>
          </td>
          <td>{{visitors.visitorType}}</td>
          <td>
            <!-- {{visitors.status}} -->
            @if(visitors.status ==='Approved'){
            <div class=" text-black font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center">
              <div class="w-2 h-2 bg-green-300 text-center"></div>Approved
            </div>
            }@else if (visitors.status==='Pending') {
            <div
              class=" text-black font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center">
              <div class="w-2 h-2 bg-yellow-300 text-center"></div>Pending
            </div>
            }@else if (visitors.status==='CheckedOut') {
            <div
              class=" text-black font-bold py-2 px-4  max-w-6 text-xs flex gap-1 items-center">
              <div class="w-2 h-2 bg-violet-300 text-center"></div>CheckedOut
            </div>
            }@else if (visitors.status==='CheckedIn') {
            <div class=" text-black font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center">
              <div class="w-2 h-2 bg-sky-300 text-center"></div>CheckedIn
            </div>
            }@else if (visitors.status==='Rejected') {
            <div class=" text-black font-bold py-2 px-4 max-w-6 text-xs flex gap-1 items-center">
              <div class="w-2 h-2 bg-red-500 text-center"></div>Rejected
            </div>
            }

          </td>
        </tr>
        }
      </tbody>
    </table>
    <app-pagination
      [totalItems]="totalItems"
      [itemsPerPage]="itemsPerPage"
      (pageChanged)="onPageChanged($event)"
    ></app-pagination>  </div>
</div>

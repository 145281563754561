<div class="navbar">
  <img src="../../../../../assets/images/Maxim-side-nav-logo.png" alt="" class="w-32 h-8 mx-10 my-6">
  <ul class="nav-list">
    @for (data of navData; track data) {
      <li class="nav-item">
        <a class="nav-link" [routerLink]="data.routeLink" [routerLinkActive]="['is-active']">
          <img [src]="data.icon" alt="Profile Icon" class="nav-link-icon">
          <p class="nav-link-text">{{ data.label }} </p>
        </a>
      </li>
    }
  </ul>
</div>

import { Component, OnInit } from '@angular/core';
import { VisitorService } from "../../../services";
import { VisitorResponseDTO } from "../../../interface";
import {DatePipe, NgForOf} from "@angular/common";
import { PaginationComponent } from "../../common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-visitor-table',
  standalone: true,
  imports: [
    PaginationComponent,
    NgForOf,
    FormsModule
  ],
  templateUrl: './visitor-table.component.html',
  styleUrls: ['./visitor-table.component.scss']
})
export class VisitorTableComponent implements OnInit {
  constructor(private visitorService: VisitorService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getPendingVisitor();
  }

  visitorResponseDTOS: VisitorResponseDTO[] = [];
  filterVisitorsDTO: VisitorResponseDTO[] = [];
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 5;
  selectedStatusFilter: string = '';

  getPendingVisitor() {
    this.visitorService.getAllVisitors().subscribe({
      next: (res) => {
        this.visitorResponseDTOS = res.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(visitorData.requestDate, 'EEE MMM dd yyyy');
          return {
            ...visitorData,
            requestDate: formattedRequestDate,
          } as VisitorResponseDTO;
        }) || [];
        this.filterVisitorsDTO=this.visitorResponseDTOS
        this.applyFilter();
        this.totalItems = this.filterVisitorsDTO.length;
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.filterVisitorsDTO = this.filterVisitorsDTO.slice(startIndex, endIndex);
      },
      error: (err: any) => {
        console.error('Error fetching pending visitors', err);
      },
    });
  }

  applyFilter() {
    if (this.selectedStatusFilter) {
      this.filterVisitorsDTO = this.visitorResponseDTOS.filter((visitor) => visitor.status === this.selectedStatusFilter);
    } else {
      this.filterVisitorsDTO = this.visitorResponseDTOS;
    }
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.getPendingVisitor();
  }

  trackByVisitor(index: number, visitor: VisitorResponseDTO): string {
    return visitor._id;
  }
}

@if (type) {
  <div
    id="notification"
    [class.bg-[#EEF9F3]]="type === 'success'"
    [class.bg-[#F7EDED]]="type === 'error'"
    [class.border-[#117143]]="type === 'success'"
    [class.border-[#E50000]]="type === 'error'"
    class="notification animate-fade-down animate-once border-[1px] "
  >
    @if (type === 'success') {
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#117143" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="text-white" style="color: #117143; ">{{ message }}</p>
    } @else if (type === 'error'){
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#E50000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="text-white" style="color: #E50000;">{{ message }}</p>
    }
  </div>
}

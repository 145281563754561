import {Injectable, signal} from '@angular/core';
import { APIRequestResources, CachedAPIRequest } from "../../core";
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, concatMap, interval, map, Observable, switchMap, take, tap} from "rxjs";
import { VisitorResponseDTO } from "../interface";


@Injectable({
  providedIn: 'root'
})
export class VisitorService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<VisitorResponseDTO[]>([]);
  all$ = this.$all.asObservable();

  private readonly pendingCountSource = new BehaviorSubject<number>(0);
  pendingCount$ = this.pendingCountSource.asObservable();

  private readonly checkedInCountSource = new BehaviorSubject<number>(0);
  checkedInCount$ = this.checkedInCountSource.asObservable();

  private readonly totalCountSource = new BehaviorSubject<number>(0);
  totalCount$ = this.totalCountSource.asObservable();

  private readonly approvedCountSource = new BehaviorSubject<number>(0);
  approvedCount$ = this.approvedCountSource.asObservable();

  private refreshCountObs$: Observable<any>;


  private readonly $visitorCount = new BehaviorSubject<number>(0);
  visitorCount$ = this.$visitorCount.asObservable();

  constructor(protected override http: HttpClient) {
    super(http, APIRequestResources.VisitorService);
    this.refreshCountObs$ = interval(3000).pipe(
      concatMap(val => {
        const randomDelay = Math.floor(Math.random() * 1000) + 3000;
        return interval(randomDelay).pipe(take(1));
      }),
      switchMap(() => this.getAllCount(true))
    );

    this.refreshCountObs$.subscribe();

  }

  getAllVisitors(refresh = false, skip = 1, limit = 10) {
    const params = {skip, limit};
    return this.get<VisitorResponseDTO[]>({}, refresh ? 'freshness' : 'performance').pipe(
      tap((res) => this.$all.next(res.data ?? []))
    );
  }


  create(createVisitorDTO: any) {
    return this.post<any>(createVisitorDTO, {});
  }

  findByStatus(status: string, refresh = false) {
    return this.get<any>({
      endpoint: `search/${status}`,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data ?? [])), map((res) => {
          const responseData = res.data ?? [];
          const count = responseData.length;
          this.$visitorCount.next(responseData.length);
          return { data: responseData, count: count };
        })
      );
  }



  updateVisitor(id: string, updateVisitorDTO: any, refresh = false) {
    const options = { suffix: id };
    return this.put<any>(updateVisitorDTO, options);
  }

  getAllCount(refresh = false) {
    return this.get<VisitorResponseDTO[]>({}, refresh ? 'freshness' : 'performance').pipe(
      tap((res) => {
        this.$all.next(res.data ?? []);
        const responseData = res.data ?? [];
        const totalCount = responseData.length;
        this.$visitorCount.next(totalCount); // Update visitor count (assuming you have $visitorCount)
        this.filterAndCountByStatus(responseData);
      }),

    );
  }

  getById(id: string, refresh = false) {
    return this.get<VisitorResponseDTO>({id}, refresh ? 'freshness' : 'performance').pipe(take(1))
  }

  private filterAndCountByStatus(data: VisitorResponseDTO[]) {
    const pending = data.filter(item => item.status === 'Pending');
    const approved = data.filter(item => item.status === 'Approved');
    const checkedIn = data.filter(item => item.status === 'CheckedIn');

    this.pendingCountSource.next(pending.length);
    this.approvedCountSource.next(approved.length);
    this.checkedInCountSource.next(checkedIn.length);
    this.totalCountSource.next(data.length);
  }
}

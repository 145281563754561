
<div class="main-container">
  <div class="heading">
    <h1 class="text-lg font-medium ">Approved Visitors</h1>
    <div>
      <input
        type="text"
        class="w-64 h-10 rounded-md p-2 font-light border ml-10 -mt-44"
        placeholder="Search Name or NIC"
        [(ngModel)]="searchTerm"
        (ngModelChange)="getApprovedVisitors()"
      />
    </div>
  </div>

  <div>
    @if (visitorResponseDTOS.length > 0) {
      @for (visitors of visitorResponseDTOS; track visitors) {
        <div class="bg-[#FAFAFA] min-w-[1171px] flex flex-row gap-6 px-10 justify-between p-3">
          <div class="w-1/3  py-4">
            <div class="flex  gap-4 justify-between">
              <div>
                <h6>{{ visitors.title }}.{{ visitors.fullName }}</h6>
              </div>
              <div class="bg-[#E8F0FB] text-[#102866] rounded-md max-w-9 max-h-7 px-3 -mt-3">
                <h6>{{ visitors.visitorType }}</h6>
              </div>
            </div>
            <p class="text-sm -mt-1 opacity-80">{{ visitors.company }}</p>
            <p class="text-sm mt-2">{{ visitors.nicNo }} - {{ visitors.contactNo }}</p>
            <p class="text-sm">{{ visitors.email }}</p>
          </div>
          <div>
            <svg width="1" height="88" viewBox="0 0 1 88" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.25" x2="0.25" y2="88" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
            </svg>
          </div>
          <div class="w-1/3  py-4">
            <div class="flex  gap-4">
              <div>
                <h6>{{ visitors.requestDate }}</h6>
              </div>
              <div class="bg-[#E8F0FB] text-[#102866] rounded-md max-w-9 max-h-7 px-3">
                <h6>{{ visitors.requestTime }}</h6>
              </div>
            </div>
            <p>{{ visitors.purpose }}</p>
          </div>
          <div>
            <svg width="1" height="88" viewBox="0 0 1 88" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.25" x2="0.25" y2="88" stroke="black" stroke-opacity="0.5" stroke-width="0.5"/>
            </svg>
          </div>
          <div class="w-1/3  py-4">
            <p>Request by - {{ visitors.requestBy }}</p>
            <p>To Contact - {{ visitors.contactPerson }}</p>
            <div class="flex gap-10 mt-4">
              <div>
                <button class="bg-[#BDF4CD] text-[#10661E] w-24 h-7 text-center rounded-md"
                        (click)="handleButtonClick(visitors)"
                        [class.opacity-50]="isButtonDisabled(visitors.requestDate, visitors.requestTime)"
                        [class.cursor-not-allowed]="isButtonDisabled(visitors.requestDate, visitors.requestTime)">
                  Checkin
                </button>
              </div>
              <div>
<!--                <button class="bg-[#F4EBBD] text-[#806600] w-24 h-7 text-center rounded-md"-->
<!--                        (click)="update(visitors._id)">Update-->
<!--                </button>-->
              </div>
            </div>
          </div>
        </div>
        <br>
      }
    } @else {
      @if (isLoading) {
        <app-loading></app-loading>
      } @else {

        <div class="text-center text-l pt-3 mt-4">
          There are no approved visitors to show.
        </div>
      }
    }
    <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                    (pageChanged)="onPageChanged($event)"></app-pagination>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from "@angular/common";
import { NotificationService } from "../../../services";
import { EmployeeService } from "../../../services";
import { EmployeeDTO } from "../../../interface";
import { FormsModule } from "@angular/forms";
import { VisitorService } from "../../../services";
import {ActivatedRoute, Router} from "@angular/router";
import {HeaderComponent} from "../../../features/header/header.component";

@Component({
  selector: 'app-create-visitor-form',
  standalone: true,
  templateUrl: './create-visitor-form.component.html',
  styleUrls: ['./create-visitor-form.component.scss'],
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    HeaderComponent
  ]
})
export class CreateVisitorFormComponent implements OnInit {

  constructor(private notificationService: NotificationService,
              private employeeService: EmployeeService,
              private visitorService: VisitorService,
              private route: ActivatedRoute, private router: Router,
  ) {
    this.setCurrentDate();
  }
  createVisitorDTO = {
    title: '',
    fullName: '',
    email: '',
    contactNo: '',
    nicNo: '',
    company: '',
    requestDate: '',
    requestTime: '',
    requestBy: '',
    contactPerson: '',
    purpose: '',
    visitorType: '',
    createdBy: 'tharuka',
  }
  employeeDTO: EmployeeDTO[] | undefined = []
  id: string = '';
  isUpdate = false;
  isSubmitting = false;


  ngOnInit(): void {
    this.getAllEmployees();
    this.route.paramMap.subscribe(params => {
      this.id = <string>params.get('id');
      if (this.id) {
        this.fetchData(this.id);
        console.log('Create Form - ID:', this.id);
      } else {
        console.log('Create Form - No ID');
      }
    });
  }
  getAllEmployees() {

    this.employeeService.getAll().subscribe(
      (response) => {
        this.employeeDTO = response.data;
      },
      (error) => {
        console.log('Error fetching Employees', error);
      }
    );
  }

  fetchData(id: string) {
    this.visitorService.getById(id, true).subscribe({
      next: (response) => {
        console.log(response.data);
        this.createVisitorDTO.title = response.data.title
        this.createVisitorDTO.fullName = response.data.fullName
        this.createVisitorDTO.nicNo = response.data.nicNo
        this.createVisitorDTO.company = response.data.company
        this.createVisitorDTO.purpose = response.data.purpose
        this.createVisitorDTO.email = response.data.email
        this.createVisitorDTO.requestBy = response.data.requestBy
        this.createVisitorDTO.contactNo = response.data.contactNo
        this.createVisitorDTO.requestDate = response.data.requestDate
        this.createVisitorDTO.requestTime = response.data.requestTime
        this.createVisitorDTO.visitorType = response.data.visitorType
        this.createVisitorDTO.contactPerson = response.data.contactPerson
      }
    })
  }

  submit() {
    this.isSubmitting = true;

    if (this.id) {
      this.isUpdate = true;
      this.update();
    } else {
      this.visitorService.create(this.createVisitorDTO).subscribe({
        next: (res) => {
          const visitorName = this.createVisitorDTO.fullName;
          const appointmentDate = this.createVisitorDTO.requestDate;
          const appointmentTime = this.createVisitorDTO.requestTime;
          const successMessage = `Appointment created for ${visitorName} Successfully`;
          this.notificationService.showNotification({
            type: 'success',
            message: successMessage,
          });
          setTimeout(() => {
            this.notificationService.hideNotification();
            this.router.navigate(['/all']);
            this.isSubmitting = false;
          }, 3000);
        },
        error: (error) => {
          let errorMessage = 'There was an error creating the appointment.';
          this.notificationService.showNotification({
            type: 'error',
            message: errorMessage,
          });
          setTimeout(() => {
            this.notificationService.hideNotification();
            this.isSubmitting = false;
          }, 5000);
          console.error('Error Creating Appointment:', error);
        },
      });
    }
  }

  update(){
    this.visitorService.updateVisitor(this.id, this.createVisitorDTO).subscribe({
      next: (res) => {
        const visitorName = this.createVisitorDTO.fullName;
        const successMessage = `Updated Appointment of ${visitorName} Successfully`;
        this.notificationService.showNotification({
          type: 'success',
          message: successMessage,
        });
        setTimeout(() => {
          this.notificationService.hideNotification();
          this.router.navigate(['/all']);
          this.isSubmitting = false;
          this.isUpdate = false;
        }, 3000);
      },
      error: (error) => {
        let errorMessage = 'There was an error updating the appointment.';
        this.notificationService.showNotification({
          type: 'error',
          message: errorMessage,
        });
        setTimeout(() => {
          this.notificationService.hideNotification();
          this.isSubmitting = false;
          this.isUpdate = false;
        }, 5000);
        console.error('Error Updating Appointment:', error);
      },
    });
  }

  fullNameTouched = false;
  emailTouched = false;
  contactTouched = false;
  nicTouched = false;
  companyTouched = false;
  titleTouched = false;
  requestTouched = false;
  timeTouched = false;
  requestByTouched = false;
  contactPersonTouched = false;
  purposeTouched = false;
  typeTouched = false;

  fullNameBlur() {
    this.fullNameTouched = true;
  }

  contactBlur() {
    this.contactTouched = true;
    if (!this.isValidPhone(this.createVisitorDTO.contactNo)) {
      this.emailTouched = true;
      this.notificationService.showNotification({
        type: 'error',
        message: 'Enter a valid phone number',
      });
      setTimeout(() => {
        this.notificationService.hideNotification();
      }, 4000);
    } else {
      this.notificationService.hideNotification();
    }
  }

  emailBlur() {
    this.emailTouched = true;
    if (!this.isValidEmail(this.createVisitorDTO.email)) {
      this.emailTouched = true;
      this.notificationService.showNotification({
        type: 'error',
        message: 'Enter a valid Email Address',
      });
      setTimeout(() => {
        this.notificationService.hideNotification();
      }, 4000);
    } else {
      this.notificationService.hideNotification();
    }
  }

  nicBlur() {
    this.nicTouched = true;
  }

  companyBlur() {
    this.companyTouched = true;
  }

  titleBlur() {
    this.titleTouched = true;
  }

  requestBlur() {
    this.requestTouched = true;
  }

  timeBlur() {
    this.timeTouched = true;
  }

  requestByBlur() {
    this.requestByTouched = true;
  }

  contactPersonBlur() {
    this.contactPersonTouched = true;
  }

  purposeBlur() {
    this.purposeTouched = true;
  }

  typeBlur() {
    this.typeTouched = true;
  }

  isValidPhone(phoneNumber: string): boolean {
    const phoneRegex = /^(?:\+?94|0)?(?:7\d{8}|0[0-9]{9})$/;
    return phoneRegex.test(phoneNumber);
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])*(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(\.[a-zA-Z]{1,})+$/;

    return emailRegex.test(email);
  }
  isSubmitValid(): boolean {
    const isEmailValid = this.createVisitorDTO.email === '' || this.isValidEmail(this.createVisitorDTO.email);
    const isPhoneValid = this.createVisitorDTO.contactNo === '' || this.isValidPhone(this.createVisitorDTO.contactNo);

    return this.createVisitorDTO.fullName?.trim() !== '' &&
      isPhoneValid &&
      this.createVisitorDTO.nicNo?.trim() !== '' &&
      this.createVisitorDTO.title.trim() !== '' &&
      this.createVisitorDTO.purpose?.trim() !== '' &&
      this.createVisitorDTO.requestBy?.trim() !== '' &&
      this.createVisitorDTO.requestDate.trim() !== '' &&
      this.createVisitorDTO.contactPerson.trim() !== '' &&
      this.createVisitorDTO.visitorType.trim() !== '' &&
      isEmailValid;
  }
  currentDate!: string;
  currentTime!: string;

  setCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    this.currentDate = `${yyyy}-${mm}-${dd}`;
  }

  validateTime(selectedTime: string) {
    const now = new Date();
    const selectedTimeDate = new Date(`1970-01-01T${selectedTime}:00Z`);
    now.setMinutes(now.getMinutes() + 1);

    if (selectedTimeDate < now) {
      alert("Please select a future time.");
      this.createVisitorDTO.requestTime = "";
    }
  }
}

import { Component } from '@angular/core';
import { PaginationComponent } from "../../common";
import { VisitorService } from "../../../services";
import { DatePipe } from "@angular/common";
import { VisitorResponseDTO } from "../../../interface";
import { NotificationService } from "../../../services";
import { Route, Router } from '@angular/router';
import { HeaderComponent } from "../../../features/header/header.component";
import { FormsModule } from '@angular/forms';
import {LoadingComponent} from "../../common/loading/loading.component";

@Component({
  selector: 'app-pending-visitor-table',
  standalone: true,

  templateUrl: './pending-visitor-table.component.html',
  styleUrl: './pending-visitor-table.component.scss',
  imports: [
    PaginationComponent,
    HeaderComponent,
    FormsModule,
    LoadingComponent
  ]
})
export class PendingVisitorTableComponent {
  constructor(private notificationService: NotificationService, private visitorService: VisitorService, private datePipe: DatePipe,
    private router: Router,

  ) {

  }
  selectedItem: any
  ngOnInit() {
    this.getAllVisitors();
  }
  update(id: string) {
    this.router.navigate(['/create', {id}]);
  }
  isLoading = true;
  visitorResponseDTOS: VisitorResponseDTO[] = [];
  readonly Approved: string = 'Approved';
  readonly Rejected: string = 'Rejected';
  searchTerm: string = '';
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 5;


  getAllVisitors() {
    this.isLoading = true;
    this.visitorService.findByStatus('Pending', true).subscribe(
      (response) => {
        this.dataLoaded();
        this.visitorResponseDTOS = response.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(visitorData.requestDate, 'EEE MMM dd yyyy');
          return {
            ...visitorData,
            requestDate: formattedRequestDate,
          } as VisitorResponseDTO;
        }) || [];


        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) =>
            visitor.fullName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            visitor.nicNo.toLowerCase().includes(this.searchTerm.toLowerCase())
        );

        this.totalItems = this.visitorResponseDTOS.length;
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.visitorResponseDTOS = this.visitorResponseDTOS.slice(
          startIndex,
          endIndex
        );
      },
      (error) => {
        console.log('Error fetching visitors', error);
        this.isLoading = false;
      },
    );
  }


  approveVisitor(id: string, status: string, visitorName: string) {
    let updateVisitorDTO = {
      status: status
    }

    this.visitorService.updateVisitor(id, updateVisitorDTO, true).subscribe({
      next: (response) => {
        if (status === 'Approved') {
          const successMessage = `Visitor ${visitorName} approved successfully!`;
          this.notificationService.showNotification({
            type: 'success',
            message: successMessage,
          });
        }else
        {
          const rejectMessage = `Visitor ${visitorName} rejected!`;
          this.notificationService.showNotification({
            type: 'success',
            message: rejectMessage,
          });
        }
      },
      error: (error) => this.showErrorNotification(error),
    });
    setTimeout(() => {
      this.notificationService.hideNotification();
    }, 5000);
    this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
      (visitor) => visitor._id !== id
    );
  }

  showErrorNotification(error: any) {
    this.notificationService.showNotification({
      type: 'alert',
      message: 'Unable to Approve this visitor!',
    });
    console.log('Error Approve this visitor', error);
  }

  onPageChanged(page: number) {
    this.currentPage = page;
    this.getAllVisitors();
  }

  isActionButtonDisabled(requestDate: string): boolean {
    const requestDateTime = new Date(`${requestDate} 00:00`);
    const currentDateTime = new Date();
    const oneDayAfterRequest = new Date(requestDateTime.getTime() + 24 * 60 * 60 * 1000);
    return currentDateTime > oneDayAfterRequest;
  }

  handleButtonClick(visitors: any): void {
    if (this.isActionButtonDisabled(visitors.requestDate)) {
      this.notificationService.showNotification({
        type: 'alert',
        message: 'Unable to Approve this visitor!',
      });
    } else {
      this.approveVisitor(visitors._id, 'Approved', visitors.fullName);
    }
  }


  filterVisitors(query: string): void {
    this.visitorResponseDTOS = this.visitorResponseDTOS.filter(visitor => {
      return (
        visitor.fullName.toLowerCase().includes(query.toLowerCase()) ||
        visitor.nicNo.toLowerCase().includes(query.toLowerCase())
      );
    });
  }

  dataLoaded() {
    this.isLoading = false;
  }

  rowClick(visitors: any) {
    this.selectedItem = visitors
  }
}

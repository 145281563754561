import {Component, OnInit} from '@angular/core';
import {PaginationComponent} from "../../common";
import {NotificationService, VisitorService} from "../../../services";
import {DatePipe} from "@angular/common";
import {VisitorDetailsDTO, VisitorResponseDTO} from "../../../interface";
import {HeaderComponent} from "../../../features/header/header.component";
import {LoadingComponent} from "../../common/loading/loading.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-all-visitors',
  standalone: true,
  templateUrl: './all-visitors.component.html',
  styleUrl: './all-visitors.component.scss',
  imports: [PaginationComponent, HeaderComponent, LoadingComponent, ReactiveFormsModule, FormsModule]
})
export class AllVisitorsComponent implements OnInit {

  constructor(
    private notificationService: NotificationService,
    private visitorService: VisitorService,
    private datePipe: DatePipe
  ) {
  }

  filterStatus  = '';
  searchTerm: string = '';
  isLoading = true;
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 5;
  readonly checkOut = 'CheckedOut';
  visitorResponseDTOS: VisitorDetailsDTO[] = [];

  onPageChanged(page: number) {
    this.currentPage = page;
    this.getAllVisitors();
  }

  ngOnInit() {
    this.getAllVisitors();
  }

  getAllVisitors() {
    this.isLoading = true;
    this.visitorService.getAllVisitors(true).subscribe({
      next: (res) => {
        this.dataLoaded();
        this.visitorResponseDTOS = res.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(
            visitorData.requestDate,
            'EEE MMM dd yyyy'
          );
          return {
            ...visitorData,
            requestDate: formattedRequestDate,
          } as VisitorResponseDTO;
        }) || [];

        // Filter the visitorResponseDTOS based on the search term
        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) =>
            (visitor.fullName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              visitor.nicNo.toLowerCase().includes(this.searchTerm.toLowerCase()))
        );

        // Filter the visitorResponseDTOS based on the selected status
        if (this.filterStatus) {
          this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
            (visitor) => visitor.status === this.filterStatus
          );
        }

        this.totalItems = this.visitorResponseDTOS.length;
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.visitorResponseDTOS = this.visitorResponseDTOS.slice(
          startIndex,
          endIndex
        );
      },
      error: (err: any) => {
        console.error('Error fetching Active visitors', err);
        this.isLoading = false;
      },
    });
  }
  onFilterStatusChange() {
    this.getAllVisitors();
  }
  dataLoaded() {
    this.isLoading = false;
  }
  trackByVisitorId(index: number, item: VisitorResponseDTO): string {
    return item._id;
  }

}

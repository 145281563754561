export const navbarData = [
  {
    routeLink: 'create',
    icon: 'assets/icons/add.svg',
    label: 'Add visitor',
  },
  {
    routeLink: 'all',
    icon: 'assets/icons/allV.svg',
    label: 'All Visitors',
  },
  {
    routeLink: 'pending',
    icon: 'assets/icons/pending.svg',
    label: 'Pending',
  },
  {
    routeLink: 'active',
    icon: 'assets/icons/active.svg',
    label: 'Active',
  },
  {
    routeLink: 'approved',
    icon: 'assets/icons/approved.svg',
    label: 'Approved',
  },
]

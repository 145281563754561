import {Injectable} from "@angular/core";
import {APIRequestResources, CachedAPIRequest} from "../../core";
import {BehaviorSubject, tap} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {EmployeeDTO} from "../interface";


@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<EmployeeDTO[]>([]);
  all$ = this.$all.asObservable();

  constructor(protected override http: HttpClient) {
    super(http, APIRequestResources.EmployeeService);
  }

  getAll(refresh = false) {
    return this.get<EmployeeDTO[]>({}, refresh ? 'freshness' : 'performance').pipe(
      tap((res) => this.$all.next(res.data ?? []))
    );
  }

}

<!-- Dashboard Header -->
<div class="px-20 mt-8 min-w-[776px]">
  <header class="flex justify-between items-center py-4 px-5 bg-white border-b border-gray-200">
    <div>
      <button class="px-11 py-2 text-sm font-medium text-white rounded"
        [ngClass]="{'bg-blue-700': currentView === 'visitor-table', 'bg-red-500': currentView === 'visitor-form'}"
        (click)="toggleVisitorForm()">{{ currentView === 'visitor-table' ? 'Add Visitor' : 'Cancel' }}
      </button>
    </div>
    <div class="flex items-center space-x-4">
      <div class="flex items-center">
        <img src="../../../../assets/icons/all.svg" alt="Profile Picture" class="w-8 h-8 rounded-full mr-2">
        <span class="text-sm font-medium text-gray-800">Tharuka Ravisara</span>
        <img src="../../../../assets/icons/pending.svg" alt="dropdown arrow" class="ml-3">
      </div>
    </div>
  </header>


  <div class="lg:flex min-w-[776px]">
    <div class="container w-5/5 lg:w-3/5">
      @if (currentView === 'visitor-table') {
      <h1 class="text-2xl px-4 mt-8 font-semibold text-gray-800 mb-4">
        Dashboard
      </h1>
      <app-pending-visitor-table></app-pending-visitor-table>
      } @else () {
      <h1 class="text-2xl px-4 mt-8 font-semibold text-gray-800 mb-4">
        Register Visitor
      </h1>
      <app-create-visitor-form></app-create-visitor-form>
      <!-- <app-navigation-card-readonly></app-navigation-card-readonly> -->
      }
    </div>
    <div class="container w-2/5 mt-24 ml-20 hidden md:block">
      <!-- <app-side-panel></app-side-panel> -->
    </div>
  </div>
</div>

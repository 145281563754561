import {Component, OnInit} from '@angular/core';
import {VisitorService} from "../../../services";


@Component({
  selector: 'app-navigation-card-readonly',
  standalone: true,
  imports: [],
  templateUrl: './navigation-card-readonly.component.html',
  styleUrl: './navigation-card-readonly.component.scss'
})
export class NavigationCardReadonlyComponent implements OnInit {

  constructor(private visitorService: VisitorService) {
  }

  pendingCount: number = 0;
  checkedInCount: number = 0;
  totalCount: number = 0;
  approvedCount: number = 0;
  private intervalId: any;


  fetchCount() {
    this.visitorService.getAllCount().subscribe({
      next: (response) => {
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.visitorService.pendingCount$.subscribe(count => this.pendingCount = count);
    this.visitorService.checkedInCount$.subscribe(count => this.checkedInCount = count);
    this.visitorService.totalCount$.subscribe(count => this.totalCount = count);
    this.visitorService.approvedCount$.subscribe(count => this.approvedCount = count);
    console.log();
  }


  ngOnInit(): void {
    this.fetchCount()
  }
}

<div class="p-6 rounded-lg border">
  <div class="flex justify-between">
    <div>
      <span class="text-2xl px-4 mt-8 font-semibold text-gray-800 mb-4">Pending Visitors</span>
    </div>
    <div>
      <select name="1" id="" class="form-input" [(ngModel)]="selectedFilter">
        <option value="">All</option>
        <option value="Today">Today</option>
        <option value="This Week">This Week</option>
        <option value="This Month">This Month</option>
      </select>
    </div>
  </div>
  <div class="grid grid-cols-1">
    @for (visitors of filteredVisitors; track visitors) {
      <br>

    <div class="p-4 bg-[#F9F7F6] flex justify-between px-10 items-center rounded-lg">
      <div><span class="text-lg text-[#303030]">{{visitors.title}}. {{visitors.fullName}}<br>
          <span class="text">{{visitors.requestDate}}  {{visitors.requestTime}}</span></span></div>
      <div></div>
      <div>
        <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.99998 4C3.10455 4 3.99999 3.10457 3.99999 2C3.99999 0.89543 3.10455 0 1.99998 0C0.895415 0 -1.52588e-05 0.89543 -1.52588e-05 2C-1.52588e-05 3.10457 0.895415 4 1.99998 4Z"
            fill="#B2B2B2" />
          <path
            d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
            fill="#B2B2B2" />
          <path
            d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
            fill="#B2B2B2" />
        </svg>
      </div>
    </div>
    }

  </div>
<div class="text-center text-[#FF5151] my-4 font-medium"><span class="">
  All Pending Visitors
</span></div>
</div>

import { Component, OnInit } from "@angular/core";
import {
  ActiveVisitorTableComponent,
  ApprovedVisitorTableComponent,
  CreateVisitorFormComponent, PendingVisitorTableComponent,
  VisitorTableComponent
} from "../../visitor";
import { VisitorService } from "../../../services";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";



@Component({
  selector: 'app-navigation-card',
  standalone: true,
  imports: [
    ApprovedVisitorTableComponent,
    ActiveVisitorTableComponent,
    VisitorTableComponent,
    CreateVisitorFormComponent,
    PendingVisitorTableComponent,
    CommonModule
  ],
  templateUrl: './navigation-card.component.html',
  styleUrl: './navigation-card.component.scss'
})
export class NavigationCardComponent implements OnInit {

  constructor(private visitorService: VisitorService) {
  }
  pendingCount: number = 0;
  checkedInCount: number = 0;
  totalCount: number = 0;
  approvedCount: number = 0;
  private intervalId: any;

  selectedCard: string = 'all';

  isActiveSelected: boolean = false;

  selectCard(card: string) {
    this.selectedCard = card;
    this.fetchCount();
    if (card === 'active') {
      this.isActiveSelected = true;
    } else {
      this.isActiveSelected = false;
    }
  }

  // selectCard(card: string) {
  //   this.selectedCard = card;
  //   this.fetchCount();
  // }

  ngOnInit(): void {
    this.fetchCount();
  }

  fetchCount() {
    this.visitorService.getAllCount().subscribe({
      next: (response) => {
      },
      error: (error) => {
        console.log(error);
      }
    });
    this.visitorService.pendingCount$.subscribe(count => this.pendingCount = count);
    this.visitorService.checkedInCount$.subscribe(count => this.checkedInCount = count);
    this.visitorService.totalCount$.subscribe(count => this.totalCount = count);
    this.visitorService.approvedCount$.subscribe(count => this.approvedCount = count);
    console.log();
  }

}

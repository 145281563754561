import {Component, OnInit} from '@angular/core';
import {VisitorResponseDTO} from "../../../interface";
import {VisitorService} from "../../../services";
import {DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";


@Component({
  selector: 'app-side-panel',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss'
})
export class SidePanelComponent implements  OnInit{


  constructor(private visitorService:VisitorService,private datePipe: DatePipe) {}
  visitorResponseDTO: VisitorResponseDTO[]  = [];
  filteredVisitors: VisitorResponseDTO[] = [];
  selectedFilter: string = '';

  getAllVisitors() {
    this.visitorService.findByStatus('Pending', true).subscribe(
      (response) => {
        this.visitorResponseDTO = response.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(visitorData.requestDate, 'EEE MMM dd yyyy');
          return { ...visitorData, requestDate: formattedRequestDate } as VisitorResponseDTO;
        });
        const filterSelect = document.querySelector('select[name="1"]');
        if (filterSelect) {
          filterSelect.addEventListener('change', this.applyFilter.bind(this));
        }
        this.applyFilter();
      },
      (error) => {
        console.log('Error fetching visitors', error);
      }
    );
  }

  applyFilter() {
    const selectedValue = this.selectedFilter || '';
    switch (selectedValue) {
      case 'Today':
        this.filteredVisitors = this.filterVisitorsForToday();
        break;
      case 'This Week':
        this.filteredVisitors = this.filterVisitorsForThisWeek();
        break;
      case 'This Month':
        this.filteredVisitors = this.filterVisitorsForThisMonth();
        break;
      default:
        this.filteredVisitors=this.visitorResponseDTO
        break;
    }
  }

  filterVisitorsForToday() {
    const today = new Date();
    return this.visitorResponseDTO.filter((visitor) => {
      const visitorDate = new Date(visitor.requestDate);
      return (
        visitorDate.getDate() === today.getDate() &&
        visitorDate.getMonth() === today.getMonth() &&
        visitorDate.getFullYear() === today.getFullYear()
      );
    });
  }

  filterVisitorsForThisWeek() {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDayOfWeek = new Date(firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    return this.visitorResponseDTO.filter((visitor) => {
      const visitorDate = new Date(visitor.requestDate);
      return visitorDate >= firstDayOfWeek && visitorDate <= lastDayOfWeek;
    });
  }

  filterVisitorsForThisMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return this.visitorResponseDTO.filter((visitor) => {
      const visitorDate = new Date(visitor.requestDate);
      return visitorDate.getMonth() === currentMonth;
    });
  }


  ngOnInit(): void {
    this.getAllVisitors()
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForOf } from "@angular/common";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalItems = 0;
  @Input() itemsPerPage = 1;
  @Output() pageChanged = new EventEmitter<number>();

  currentPage = 1;
  totalPages = 0;
  visiblePageNumbers: number[] = [];
  startPage = 1;
  endPage = 3;

  ngOnInit() {
    this.calculateTotalPages();
    this.updateVisiblePageNumbers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['totalItems'] || changes['itemsPerPage']) {
      this.calculateTotalPages();
      this.updateVisiblePageNumbers();
    }
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  updateVisiblePageNumbers() {
    this.visiblePageNumbers = [];
    for (let i = this.startPage; i <= this.endPage && i <= this.totalPages; i++) {
      this.visiblePageNumbers.push(i);
    }
  }

  previousPages() {
    if (this.startPage > 1 && this.totalPages > 1) {
      this.startPage = Math.max(this.startPage - 3, 1);
      this.endPage = Math.min(this.startPage + 2, this.totalPages);
      this.updateVisiblePageNumbers();
    }
  }

  nextPages() {
    if (this.endPage < this.totalPages && this.totalPages > 1) {
      this.endPage = Math.min(this.endPage + 3, this.totalPages);
      this.startPage = Math.max(this.endPage - 2, 1);
      this.updateVisiblePageNumbers();
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
    this.pageChanged.emit(this.currentPage);
  }
}

import { Component, OnInit } from '@angular/core';
import { PaginationComponent } from '../../common';
import { VisitorService } from '../../../services';
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import { VisitorResponseDTO } from '../../../interface';
import { NotificationService } from '../../../services';
import { HeaderComponent } from "../../../features/header/header.component";
import {LoadingComponent} from "../../common/loading/loading.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
    selector: 'app-active-visitor-table',
    standalone: true,
    templateUrl: './active-visitor-table.component.html',
    styleUrls: ['./active-visitor-table.component.scss'],
  imports: [PaginationComponent, NgForOf, HeaderComponent, NgIf, LoadingComponent, ReactiveFormsModule, FormsModule]
})
export class ActiveVisitorTableComponent implements OnInit {

  constructor(
    private notificationService: NotificationService,
    private visitorService: VisitorService,
    private datePipe: DatePipe
  ) {}

  searchTerm: string = '';
  isLoading = true;
  readonly checkOut = 'CheckedOut';
  visitorResponseDTOS: VisitorResponseDTO[] = [];
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 5;

  ngOnInit() {
    this.getActiveVisitors();
  }

  trackByVisitorId(index: number, item: VisitorResponseDTO): string {
    return item._id;
  }

  getActiveVisitors() {
    this.isLoading = true;
    this.visitorService.findByStatus('CheckedIn', true).subscribe({
      next: (res) => {
        this.dataLoaded();
        this.visitorResponseDTOS = res.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(
            visitorData.requestDate,
            'EEE MMM dd yyyy'
          );
          return {
            ...visitorData,
            requestDate: formattedRequestDate,
          } as VisitorResponseDTO;
        }) || [];

        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) =>
            visitor.fullName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            visitor.nicNo.toLowerCase().includes(this.searchTerm.toLowerCase())
        );

        this.totalItems = this.visitorResponseDTOS.length;
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.visitorResponseDTOS = this.visitorResponseDTOS.slice(
          startIndex,
          endIndex
        );
      },
      error: (err: any) => {
        console.error('Error fetching Active visitors', err);
        this.isLoading = false;
      },
    });
  }

  checkedOutVisitors(id: string, status: string , visitorName: string) {
    let updateVisitorDTO = {
      status: status,
    };

    this.visitorService.updateVisitor(id, updateVisitorDTO, true).subscribe({
      next: (response) => {
        const successMessage = `Visitor ${visitorName} CheckedOut successfully!`;
        this.notificationService.showNotification({
          type: 'success',
          message: successMessage,
        });
        setTimeout(() => {
          this.notificationService.hideNotification();
        }, 5000);

        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) => visitor._id !== id
        );
      },
      error: (error) => this.showErrorNotification(error),
    });
  }

  dataLoaded() {
    this.isLoading = false;
  }

  showErrorNotification(error: any) {
    this.notificationService.showNotification({
      type: 'alert',
      message: 'Unable to CheckOut this visitor!',
    });
    console.log('Error CheckOut this visitor', error);
  }
  onPageChanged(page: number) {
    this.currentPage = page;
    this.getActiveVisitors();
  }
}

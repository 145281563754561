<app-header></app-header>
<div class="px-8 mt-8">
  <div class="main-container">
    <div class="heading">
      <div class="heading">
        @if (id) {
        <h1 class="text-lg font-medium pb-5">Update Appointment</h1>
        } @else {
        <h1 class="text-lg font-medium pb-5">Create Appointment</h1>
        }
      </div>
    </div>
  <div class="border rounded-lg shadow-sm">
    <div class="text-left py-6 px-12  grid grid-cols-3 gap-4">
      <div>
        <span class="text">Title</span>
        <select name="" id="title" class="form-input" [(ngModel)]="createVisitorDTO.title" required #titleInput
                (blur)="titleBlur()">
          <option value="Mr">MR</option>
          <option value="Mrs">MRS</option>
          <option value="Ms">Ms</option>
        </select>
        @if (titleInput.validity.valueMissing && titleTouched) {
          <div class="text-red-500 text-xs">
            Title is required
          </div>
        }
      </div>
      <div>
        <span class="text">Name</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.fullName" required #fullNameInput
               (blur)="fullNameBlur()">
        @if (fullNameInput.validity.valueMissing && fullNameTouched) {
          <div class="text-red-500 text-xs">
            Full Name is required.
          </div>
        }
      </div>
      <div>
        <span class="text">Email</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.email" #emailInput
               (blur)="emailBlur()">
        <div *ngIf="emailTouched && createVisitorDTO.email !== '' && !isValidEmail(createVisitorDTO.email)" class="text-red-500 text-xs">
          Please Enter Valid Email.
        </div>
      </div>
      <div>
        <span class="text">Contact Number</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.contactNo"
               #contactInput
               (blur)="contactBlur()">
        <div *ngIf="contactTouched && createVisitorDTO.contactNo !== '' && !isValidPhone(createVisitorDTO.contactNo)" class="text-red-500 text-xs">
          Please Enter Valid Number
        </div>
      </div>
      <div>
        <span class="text">NIC or Passport</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.nicNo" required #nicInput
          (blur)="nicBlur()">
        @if (nicInput.validity.valueMissing && nicTouched) {
        <div class="text-red-500 text-xs">
          NIC or Passport Number required.
        </div>
        }
      </div>
      <div>
        <span class="text">Company</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.company" required #companyInput
          (blur)="companyBlur()">
        @if (companyInput.validity.valueMissing && companyTouched) {
        }
      </div>
      <div>
        <span class="text">Requested Date</span>
        <input type="date" class="form-input" id="requestedDate" [(ngModel)]="createVisitorDTO.requestDate" required
               #requestDateInput (blur)="requestBlur()" [min]="currentDate">
        @if (requestDateInput.validity.valueMissing && requestTouched) {
          <div class="text-red-500 text-xs">
            Request Date Is Required
          </div>
        }
      </div>
      <div>
        <span class="text">Requested Time</span>
        <input type="time" class="form-input" id="requestedTime" [(ngModel)]="createVisitorDTO.requestTime" required
          >
      </div>
      <div>
        <span class="text">Requested By</span>
        <select name="" id="requestedBy" class="form-input" [(ngModel)]="createVisitorDTO.requestBy" required
          #requestByInput (blur)="requestByBlur()">
          @for (employee of employeeDTO; track employee) {
          <option value="{{employee.displayName}}">{{ employee.displayName }}</option>

          }
        </select>
        @if (requestByInput.validity.valueMissing && requestByTouched) {
        <div class="text-red-500 text-xs">
          This Field is Required
        </div>
        }
      </div>
      <div>
        <span class="text">Contact Person</span>
        <select name="" id="contactPerson" class="form-input" [(ngModel)]="createVisitorDTO.contactPerson" required
          #contactPersonInput (blur)="contactPersonBlur()">
          @for (employee of employeeDTO; track employee) {
          <option value="{{employee.displayName}}">{{ employee.displayName }}</option>
          }
        </select>
        @if (contactPersonInput.validity.valueMissing && contactPersonTouched) {
        <div class="text-red-500 text-xs">
          This Field is Required
        </div>
        }
      </div>
      <div>
        <span class="text">Purpose</span>
        <input type="text" class="form-input" [(ngModel)]="createVisitorDTO.purpose" required #purposeInput
          (blur)="purposeBlur()">
        @if (purposeInput.validity.valueMissing && purposeTouched) {
        <div class="text-red-500 text-xs">
          This Field is Required
        </div>
        }
      </div>
      <div>
        <span class="text">Type</span>
        <select name="" id="" class="form-input" [(ngModel)]="createVisitorDTO.visitorType" required #typeInput
                (blur)="typeBlur()">
          <option value="Requested">Requested</option>
          <option value="WalkedIn">WalkedIn</option>
        </select>
        @if (typeInput.validity.valueMissing && typeTouched) {
          <div class="text-red-500 text-xs">
            This Field is Required
          </div>
        }
      </div>
    </div>
    <div class="flex justify-end gap-8 mx-12 mb-2">
      <button (click)="submit()" class="bg-[#E8F0FB] text-[#102866] w-24 h-9 rounded-md"
              [ngClass]="{'opacity-50 cursor-not-allowed': isSubmitting || !isSubmitValid(), 'hover:bg-[#D0E1FF] transition-colors duration-300': !isSubmitting && isSubmitValid()}"
              [disabled]="isSubmitting || !isSubmitValid()">
        {{ isSubmitting ? (id ? 'Updating...' : 'Submitting...') : (id ? 'Update' : 'Create') }}
      </button>
    </div>
  </div>
</div>

<div class="pagination">
  <button (click)="previousPages()" [disabled]="startPage === 1" class="pagination-button"><</button>
  <button
    *ngFor="let pageNumber of visiblePageNumbers"
    (click)="goToPage(pageNumber)"
    [class.active]="pageNumber === currentPage"
    class="pagination-button"
  >
    {{ pageNumber }}
  </button>
  <button (click)="nextPages()" [disabled]="endPage >= totalPages" class="pagination-button">></button>
</div>


import { Component } from '@angular/core';
import { navbarData } from './nav-data';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
  currentLable = ""
  subMenu = "nested-nav-list-hidden"
  navData = navbarData;
  submenuTogle(lable: string) {

    if (lable != this.currentLable) {
      this.subMenu = "nested-nav-list-hidden"
      this.subMenu = "nested-nav-list-display"
      this.currentLable = lable
    } else {
      this.subMenu = "nested-nav-list-hidden"
      this.currentLable = ""
    }

  }

}

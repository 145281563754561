import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

type NotificationType = 'success' | 'error' | 'alert' | 'confirm';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSubject = new BehaviorSubject<{ type: NotificationType; message: string } | null>(null);

  showNotification(notification: { type: NotificationType; message: string }) {
    this.notificationSubject.next(notification);
  }

  getNotification$() {
    return this.notificationSubject.asObservable();
  }

  hideNotification() {
    this.notificationSubject.next(null);
  }
}

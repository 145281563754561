import { Injectable } from '@angular/core'
import { SignInResponse, SigninSession } from '../types'
import { Router } from '@angular/router'

import { HttpClient, HttpHeaders } from "@angular/common/http"
import {ApiConfig} from "../config"
import {APIResponse} from "../interfaces"
import {catchError, of, tap} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(protected http: HttpClient, private router: Router) {
  }
  signIn =  (authKey: string) => {
    const headers = new HttpHeaders({
      'auth-Key': authKey
    })
    return this.http.post<APIResponse<SignInResponse>>(ApiConfig.authURL, {}, {headers: headers}).pipe(
      tap (res => {
        if(res.data) {
          sessionStorage.setItem("user-auth", JSON.stringify(res.data))
        }
      }),
      catchError(error => {
        console.error(`Sign-in failed:`, error)
        return of(`Sign-in failed due to error: ${error.message}`)
      })
    )
  }

  private userAuthenticated: boolean = false

  isUserExist(): boolean {
    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU"
    if (userSessionData != "NAU") {
      let userData: SigninSession = JSON.parse(userSessionData)
      if (!!userData.accessToken) {
        return true
      }
    }
    return false
  }

  getAuthToken(): string {

    let userSessionData = sessionStorage.getItem("user-auth") ?? "NAU"
    if (userSessionData === "NAU") { return "" }

    let userData: SigninSession = JSON.parse(userSessionData)
    return userData.refreshToken

  }

  userSignOut() {
    this.userAuthenticated = false
    sessionStorage.removeItem("user-auth")
    this.router.navigate(['/login'])
  }
}

import { Component, OnInit } from '@angular/core';
import { PaginationComponent } from '../../common';
import { VisitorService } from '../../../services';
import { DatePipe, NgForOf } from '@angular/common';
import { VisitorResponseDTO } from '../../../interface';
import { NotificationService } from '../../../services';
import {Router} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoadingComponent} from "../../common/loading/loading.component";

@Component({
  selector: 'app-approved-visitor-table',
  standalone: true,
  imports: [PaginationComponent, NgForOf, ReactiveFormsModule, FormsModule, LoadingComponent],
  templateUrl: './approved-visitor-table.component.html',
  styleUrls: ['./approved-visitor-table.component.scss'],
})
export class ApprovedVisitorTableComponent implements OnInit {
  constructor(
    private notificationService: NotificationService,
    private visitorService: VisitorService,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  visitorResponseDTOS: VisitorResponseDTO[] = [];
  isLoading = true;
  readonly checkIn = 'CheckedIn';
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 5;
  searchTerm: string = '';


  ngOnInit() {
    this.getApprovedVisitors();
  }

  trackByVisitorId(index: number, item: VisitorResponseDTO): string {
    return item._id;
  }

  getApprovedVisitors() {
    this.isLoading = true;
    this.visitorService.findByStatus('Approved', true).subscribe({
      next: (res) => {
        this.dataLoaded();
        this.visitorResponseDTOS = res.data?.map((visitorData: any) => {
          const formattedRequestDate = this.datePipe.transform(
            visitorData.requestDate,
            'EEE MMM dd yyyy'
          );
          return {
            ...visitorData,
            requestDate: formattedRequestDate,
          } as VisitorResponseDTO;
        }) || [];

        // Show only today's visitors
        const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) =>
            visitor.requestDate === this.datePipe.transform(today, 'EEE MMM dd yyyy')
        );

        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) =>
            visitor.fullName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            visitor.nicNo.toLowerCase().includes(this.searchTerm.toLowerCase())
        );

        this.totalItems = this.visitorResponseDTOS.length;
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        this.visitorResponseDTOS = this.visitorResponseDTOS.slice(
          startIndex,
          endIndex
        );
      },
      error: (err: any) => {
        console.error('Error fetching approved visitors', err);
        this.isLoading = false;
      },
    });
  }
  update(id: string) {
    this.router.navigate(['/create', {id}]);
  }

  checkedInVisitors(id: string, status: string, visitorName: string) {
    let updateVisitorDTO = {
      status: status,
    };

    this.visitorService.updateVisitor(id, updateVisitorDTO, true).subscribe({
      next: (response) => {
        const successMessage = `Visitor ${visitorName} CheckedIn Successfully`;
        this.notificationService.showNotification({
          type: 'success',
          message: successMessage,
        });
        setTimeout(() => {
          this.notificationService.hideNotification();
        }, 5000);

        this.visitorResponseDTOS = this.visitorResponseDTOS.filter(
          (visitor) => visitor._id !== id
        );
      },
      error: (error) => this.showErrorNotification(error),
    });
  }

  isButtonDisabled(requestDate: string, requestTime: string): boolean {
    const requestDateTime = new Date(`${requestDate} ${requestTime}`);
    const currentDateTime = new Date();

    // Check if the requested date matches the current date (year, month, day)
    const isSameDay = requestDateTime.getFullYear() === currentDateTime.getFullYear() &&
      requestDateTime.getMonth() === currentDateTime.getMonth() &&
      requestDateTime.getDate() === currentDateTime.getDate();

    if (isSameDay) {
      return false;
    }

    // If not the same day, apply the original logic with 30-minute buffer
    const oneHourBeforeMeeting = new Date(requestDateTime.getTime() - 30 * 60 * 1000);
    const oneHourAfterMeeting = new Date(requestDateTime.getTime() + 30 * 60 * 1000);

    return currentDateTime < oneHourBeforeMeeting || currentDateTime > oneHourAfterMeeting;
  }


  dataLoaded() {
    this.isLoading = false;
  }

  showErrorNotification(error: any) {
    this.notificationService.showNotification({
      type: 'alert',
      message: 'Unable to CheckIn this visitor!',
    });
    console.log('Error CheckOut this visitor', error);
  }
  onPageChanged(page: number) {
    this.currentPage = page;
    this.getApprovedVisitors();
  }
  handleButtonClick(visitors: any): void {
    if (this.isButtonDisabled(visitors.requestDate, visitors.requestTime)) {
      this.notificationService.showNotification({
        type: 'alert',
        message: 'Unable to Approve this visitor!',
      });
    } else {
      this.checkedInVisitors(visitors._id, this.checkIn, visitors.fullName);
    }
  }
}

import { Component } from '@angular/core';
import { NavigationCardComponent } from "../../shared/layout";
import { VisitorTableComponent } from "../../shared/visitor";
import { CreateVisitorFormComponent } from "../../shared/visitor";
import { NgClass } from "@angular/common";
import {
  NavigationCardReadonlyComponent
} from "../../shared/layout";
import { SidePanelComponent } from "../../shared/layout/side-panel/side-panel.component";
import { EmployeeService, NotificationService, VisitorService } from "../../services";
import { Router } from "@angular/router";
import { PendingVisitorTableComponent } from "../../shared/visitor/pending-visitor-table/pending-visitor-table.component";

@Component({
    selector: 'app-layout',
    standalone: true,
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    imports: [
        NavigationCardComponent,
        VisitorTableComponent,
        CreateVisitorFormComponent,
        NgClass,
        NavigationCardReadonlyComponent,
        SidePanelComponent,
        PendingVisitorTableComponent
    ]
})

export class DashboardComponent {
  currentView: 'visitor-table' | 'visitor-form' = 'visitor-table';

  constructor(private notificationService: NotificationService) { }


  toggleVisitorForm() {
    this.currentView = this.currentView === 'visitor-table' ? 'visitor-form' : 'visitor-table';
    this.notificationService.hideNotification();
  }
}
